.mainColor{
  color: #2FB394;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.MSSurfaceTablet Option,
.MSSurfaceTablet Input,
.MSSurfaceTablet Button {
  /* min-width: 200px; */
  min-height:50px !important;
}


.absoluteCenter {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.localSpinnerPosition{
  display: flex;
  justify-content: center;
  align-items: center;
  height:100
}

.footerStyle {
  text-align: center;
  background-color: #204E57;
  color: #c1c1c1;
}

.scrolledBox {
  overflow: auto;
  max-height: 600px;
  display: block;
}

.rowStyle{
  border-left: 4px solid;
  border-color: #2FB394;
  padding-left: 10px;
}


.parent {
  display: flex;
  justify-content: space-between;
}

.child {
  flex-grow: 1;
}

.MainLayoutPageStyle {
  padding: "20px";
  background-color: "#F5F5F5";
  height: "100%";
}


.table-row-light {
  background-color: #ffffff;
}
.table-row-dark {
  background-color: #f1f1f1;
}
